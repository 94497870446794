<template>
  <div :class="$style.scene">
    <div :class="$style.box">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.tip">
        <p :class="$style.title">场景管理</p>
        <p :class="$style.desc">对访问入口设置场景，不同的场景带有不同的场景值，可以用于分场景统计数据。</p>
      </div>
    </div>
    <div :class="$style.view">
      <div :class="$style.head">
        <p>场景列表</p>
        <BjButton v-permission="'trace_source.entrance.scene-create'" type="primary" @click="onAdd()">
          <i class="ri-add-line left" />
          添加场景
        </BjButton>
      </div>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #cover="item">
          <div :class="$style.box">
            <div :class="$style.cover">
              <img :src="item.cover.cover" />
            </div>
          </div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
    <bj-modal
      :title="title"
      :visible="visible"
      :width="520"
      :body-style="{ height: '400px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            场景名称
          </a-col>
          <a-col span="19" class="tip">
            <BjInput v-model="add.name" placeholder="请输入分组名称" />
          </a-col>
        </a-row>
        <a-row class="mt-10 mb-20">
          <a-col span="5" />
          <a-col span="19" class="tip">
            场景名称符合场景需求，指你要把文化入口要放在那些场景，并且可以统计这些场景的用户访问数据。
          </a-col>
        </a-row>
        <a-row>
          <a-col span="5">描述信息</a-col>
          <a-col span="19">
            <a-textarea
              v-model="add.describe"
              placeholder="请输入此分组的描述信息"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { sceneService } from '@/service'

const service = new sceneService()

export default {
  name: 'Home',
  data() {
    return {
      title: '添加场景',
      visible: false,
      id: null,
      add: {
        name: null,
        describe: null,
      },
      loading: false,
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '场景名称',
          dataIndex: 'name',
          ellipsis: true,
        },
        {
          title: '场景值',
          dataIndex: 'value',
        },
        {
          title: '包含入口数量',
          dataIndex: 'entrance_num',
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link v-permission='trace_source.entrance.scene-edit' type='primary' onClick={() => this.onEdit(item)}>
              编辑
            </bj-link>,
            <a-popconfirm title='确定要彻底删除吗？删除之后就不能再次找回。' onConfirm={() => this.onDelete(item)}>
              <bj-link v-permission='trace_source.entrance.scene-delete' type='danger'>
                删除
              </bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    onAdd() {
      this.visible = true
      this.id = null
      this.add = {
        name: null,
        describe: null,
      }
    },
    onEdit(item) {
      this.visible = true
      this.id = item.id
      this.add.name = item.name
      this.add.describe = item.describe
    },
    async onDelete(item) {
      try {
        await service.delete({ id: item.id })
        this.$message.success('删除成功')
        this.page = 1
        this.getList()
      } catch (e) {}
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {}
    },
    async handleOk() {
      try {
        this.id ? await service.edit({ id: this.id, ...this.add }) : await service.add(this.add)
        this.$message.success(this.id ? '修改成功' : '添加成功')
        this.handleCancel()
        this.getList()
      } catch (e) {}
    },
    handleCancel() {
      this.visible = false
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.scene {
  .box {
    display: flex;
    height: 90px;
    padding: 20px;
    background: #fff;

    .icon {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      line-height: 50px;
      text-align: center;
      background: #fafafa;
      border-radius: 100%;

      i {
        color: #000;
        font-size: 20px;
      }
    }

    .tip {
      .title {
        margin-bottom: 4px;
        color: #000;
        font-size: 16px;
      }

      .desc {
        color: #5c5c5c;
        font-size: 12px;
      }
    }
  }

  .view {
    min-height: calc(100vh - 262px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
